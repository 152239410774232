import axios0 from "axios";

export const axios = axios0.create();

axios.interceptors.request.use((config) => {
  if (localStorage.getItem("token"))
    config.headers.set("Authorization", localStorage.getItem("token"));

  return config;
});

axios.interceptors.response.use(
  (resp) => {
    return resp;
  },
  (error) => {
    if (error.response.status === 403) {
      localStorage.removeItem("token");
      window.location.reload();
    }

    return Promise.reject(error);
  }
);
