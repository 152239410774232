import { useState } from "react";
import { Typography, Button, Input, Checkbox, Form } from "antd";
import { useNavigate } from "react-router-dom";

import { axios } from "../axios";
import { auth } from "../store/auth";

const { Paragraph } = Typography;

export function LoginPage() {
  const navigate = useNavigate();

  const [errorPrivacy, setErrorPrivacy] = useState(false);
  const [errors, setErrors] = useState({});

  return (
    <div className="password-page">
      <div className="password-page__content">
        <Form
          layout="vertical"
          onFinish={async ({ email, password, privacy }) => {
            setErrors({});
            setErrorPrivacy(false);

            if (privacy !== true) {
              setErrorPrivacy(true);
              return;
            }

            try {
              const resp = await axios.post("/api/log-in", { email, password });
              localStorage.setItem("token", resp.data.token);

              auth.auth = true;
              auth.user = resp.data.user;

              navigate("/r/dashboard");
            } catch (e) {
              const message = e.response.data.message;

              switch (message) {
                case "User not found":
                  setErrors({ email: "User not found." });
                  break;
                case "Password invalid":
                  setErrors({ password: "Password invalid" });
                  break;
                default:
                  setErrors(e.response.data);
              }
            }
          }}
        >
          <Form.Item
            label="Email:"
            name="email"
            validateStatus={errors.email ? "error" : null}
            help={errors.email}
          >
            <Input type="email" placeholder="Your email" />
          </Form.Item>

          <Form.Item
            label="Password:"
            name="password"
            validateStatus={errors.password ? "error" : null}
            help={errors.password}
          >
            <Input type="password" placeholder="Your Password" />
          </Form.Item>
          <Form.Item
            name="privacy"
            valuePropName="checked"
            validateStatus={errorPrivacy ? "error" : null}
            help={errorPrivacy ? "Please, accept our Privacy" : null}
          >
            <Checkbox>
              By clicking Log In, you agree to our Privacy Policy.
            </Checkbox>
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit">Log In</Button>
          </Form.Item>
        </Form>

        <Paragraph style={{ fontStyle: "italic", fontSize: 12 }}>
          Photographs and information in this database are private and the
          solely the property of Health Access, LLC. By accessing this database
          the user agrees to maintain the privacy of this database. Photographs
          and elements of the database are not to be copied or disseminated in
          any way.
        </Paragraph>
      </div>
    </div>
  );
}
