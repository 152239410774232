import "./global.scss";
import "./App.scss";

import { useState } from "react";
import { createBrowserRouter, RouterProvider, Redirect } from "react-router-dom";

import { SamplesPage } from "./page/SamplesPage";
import { PasswordPage } from "./page/PasswordPage";
import { LoginPage } from "./page/LoginPage";
import { AuthPage } from "./page/AuthPage";

const router = createBrowserRouter([
  {
    path: "/r",
    element: <AuthPage />,
    children: [
      {
        path: "/r/dashboard",
        element: <SamplesPage />
      }
    ],
  },
  {
    path: "/",
    element:  <LoginPage />
  },
]);

function App() {
  
  return (<RouterProvider router={router} />);
}

export default App;
