import "./SamplesPage.scss";
import "react-datepicker/dist/react-datepicker.css";

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { Spin, Pagination, DatePicker, Card, Space, Select, Form, Checkbox } from "antd";
import * as _ from "lodash";

import { axios } from "../axios";

import { Item } from "../components/Item";

const { RangePicker } = DatePicker;

const SIZE = 20;

function Filter({ onChange }) {
  const [searchParams] = useSearchParams();

  return (
    <Card>
      <div>
        <b>General</b>
      </div>
      <Space>
        <Form layout="vertical">
          <Form.Item label="Date:">
            <RangePicker
              value={
                searchParams.get("startDate")
                  ? [
                      dayjs(searchParams.get("startDate")),
                      dayjs(searchParams.get("endDate")),
                    ]
                  : null
              }
              onChange={(data) => {
                onChange({
                  startDate: data[0].format("YYYY-MM-DD"),
                  endDate: data[1].format("YYYY-MM-DD"),
                });
              }}
            />
          </Form.Item>
        </Form>

        <Form layout="vertical">
          <Form.Item label="Symmetry Status:">
            <Select
              style={{ width: 120 }}
              onChange={(val) => onChange({ statusSymmetry: val })}
              value={searchParams.get("statusSymmetry") || ""}
              options={[
                {
                  value: "",
                  label: "None",
                },
                {
                  value: "ok",
                  label: "Ok",
                },
                {
                  value: "unprocessed",
                  label: "Unprocessed",
                },
                {
                  value: "refer",
                  label: "Refer",
                },
                {
                  value: "error",
                  label: "Error",
                },
              ]}
            />
          </Form.Item>
        </Form>

        <Form layout="vertical">
          <Form.Item label="Left ML Result:">
            <Select
              style={{ width: 120 }}
              onChange={(val) => onChange({ leftMLResult: val })}
              value={searchParams.get("leftMLResult") || ""}
              options={[
                {
                  value: "",
                  label: "None",
                },
                {
                  value: "ok",
                  label: "Ok",
                },
                {
                  value: "unprocessed",
                  label: "Unprocessed",
                },
                {
                  value: "refer",
                  label: "Refer",
                },
                {
                  value: "error",
                  label: "Error",
                },
              ]}
            />
          </Form.Item>
        </Form>

        <Form layout="vertical">
          <Form.Item label="Right ML Result:">
            <Select
              style={{ width: 120 }}
              onChange={(val) => onChange({ rightMLResult: val })}
              value={searchParams.get("rightMLResult") || ""}
              options={[
                {
                  value: "",
                  label: "None",
                },
                {
                  value: "ok",
                  label: "Ok",
                },
                {
                  value: "unprocessed",
                  label: "Unprocessed",
                },
                {
                  value: "refer",
                  label: "Refer",
                },
                {
                  value: "error",
                  label: "Error",
                },
              ]}
            />
          </Form.Item>
        </Form>

        <Form layout="vertical">
          <Form.Item label="Platform:">
            <Select
              style={{ width: 120 }}
              onChange={(val) => onChange({ platform: val })}
              value={searchParams.get("platform") || ""}
              options={[
                {
                  value: "",
                  label: "None",
                },
                {
                  value: "ANDROID",
                  label: "Android",
                },
                {
                  value: "IOS",
                  label: "IOS",
                },
              ]}
            />
          </Form.Item>
        </Form>
      </Space>

      <div>
        <b>Personal</b>
      </div>
      <Space>
        <Form layout="vertical">
          <Form.Item label="Left Human Label:">
            <Select
              style={{ width: 120 }}
              onChange={(val) => onChange({ leftHumanLabel: val })}
              value={searchParams.get("leftHumanLabel") || ""}
              options={[
                {
                  value: "",
                  label: "None",
                },
                  {
                      value: "unset",
                      label: "Unset",
                  },
                {
                  value: "pass",
                  label: "Pass",
                },
                {
                  value: "refer",
                  label: "Refer",
                },
                {
                  value: "error",
                  label: "Error",
                },
                {
                  value: "unprocessed",
                  label: "Unprocessed",
                },
              ]}
            />
          </Form.Item>
        </Form>

        <Form layout="vertical">
          <Form.Item label="Right Human Label:">
            <Select
              style={{ width: 120 }}
              onChange={(val) => onChange({ rightHumanLabel: val })}
              value={searchParams.get("rightHumanLabel") || ""}
              options={[
                {
                  value: "",
                  label: "None",
                },
                {
                  value: "pass",
                  label: "Pass",
                },
                {
                  value: "refer",
                  label: "Refer",
                },
                {
                  value: "error",
                  label: "Error",
                },
                {
                  value: "unprocessed",
                  label: "Unprocessed",
                },
              ]}
            />
          </Form.Item>
        </Form>

        <Form layout="vertical">
          <Form.Item label="Symmetry Human Label:">
            <Select
              style={{ width: 120 }}
              onChange={(val) => onChange({ symmetryHumanLabel: val })}
              value={searchParams.get("symmetryHumanLabel") || ""}
              options={[
                {
                  value: "",
                  label: "None",
                },
                {
                  value: "pass",
                  label: "Pass",
                },
                {
                  value: "refer",
                  label: "Refer",
                },
                {
                  value: "error",
                  label: "Error",
                },
                {
                  value: "unprocessed",
                  label: "Unprocessed",
                },
              ]}
            />
          </Form.Item>
        </Form>

        <Form>
        <Form.Item>
            <Checkbox 
            checked={searchParams.get("noMarkers") === "true"}
            onChange={e => {
              onChange({noMarkers: e.target.checked})
            }}>No markers</Checkbox>
          </Form.Item>
        </Form>
      </Space>
    </Card>
  );
}

export function SamplesPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const size = +searchParams.get("size") || SIZE;
  const page = +searchParams.get("page") || 0;

  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const statusSymmetry = searchParams.get("statusSymmetry");
  const leftMLResult = searchParams.get("leftMLResult");
  const rightMLResult = searchParams.get("rightMLResult");
  const platform = searchParams.get("platform");

  const leftHumanLabel = searchParams.get("leftHumanLabel");
  const rightHumanLabel = searchParams.get("rightHumanLabel");
  const symmetryHumanLabel = searchParams.get("symmetryHumanLabel");

  const noMarkers = searchParams.get("noMarkers");

  const [totalElements, setTotalElements] = useState(0);
  const [samples, setSamples] = useState([]);
  const [loading, setLoading] = useState(false);

  function changeUrl(params, changePage) {
    var searchQuery0 = {
      page,
      size,
      startDate,
      endDate,
      statusSymmetry,
      leftMLResult,
      rightMLResult,
      platform,
      leftHumanLabel,
      rightHumanLabel,
      symmetryHumanLabel,
      noMarkers,
      ...params,
    };

    var searchQuery = {};

    for (let name of _.keys(searchQuery0)) {
      if (!!searchQuery0[name]) {
        searchQuery[name] = searchQuery0[name];
      }
    }

    if (changePage) {
      delete searchQuery["page"];
    }

    setSearchParams(searchQuery);
  }

  function fetch() {
    setLoading(true);
    axios
      .get(`/api/sample`, {
        params: {
          page,
          size,
          startDate,
          endDate,
          statusSymmetry,
          leftMLResult,
          rightMLResult,
          platform,
          leftHumanLabel,
          rightHumanLabel,
          symmetryHumanLabel,
          noMarkers
        },
      })
      .then((resp) => {
        setLoading(false);
        setSamples(resp.data.content);
        setTotalElements(resp.data.totalElements);
      })
      .catch((e) => setLoading(false));
  }

  useEffect(
    () => fetch(page),
    [
      page,
      size,
      startDate,
      endDate,
      statusSymmetry,
      leftMLResult,
      rightMLResult,
      platform,
      leftHumanLabel,
      rightHumanLabel,
      symmetryHumanLabel,
      noMarkers,
    ]
  );

  return (
    <div>
      <Filter
        onChange={(params) => {
          changeUrl(params, true);
        }}
      />

      {!loading && (
        <ul className="samples-list">
          {samples.map((sample) => {
            return (
              <li key={sample.id} className="samples-list__item">
                <Item sample={sample} />
              </li>
            );
          })}
        </ul>
      )}

      {loading && (
        <div
          style={{
            height: "calc(100vh - 300px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin />
        </div>
      )}

      {!loading && samples.length === 0 && (
        <div
          style={{
            height: "calc(100vh - 300px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <b>No data</b>
        </div>
      )}

      {samples.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: 100,
          }}
        >
          <Pagination
            current={page + 1}
            total={totalElements}
            showSizeChanger={false}
            defaultPageSize={20}
            onChange={(page) => {
              changeUrl({
                page: page - 1,
              });
            }}
          />
        </div>
      )}
    </div>
  );
}
