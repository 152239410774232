import React from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { auth } from "../store/auth";

export const InnerLayout = observer(({ children }) => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", margin: 24 }}>
        <b>KidsvisionCheck</b>

        <div style={{alignSelf: "flex-end"}}>
          {auth.user.name} ({auth.user.email})
          <Link
            to="/"
            onClick={() => {
              localStorage.removeItem("token");
              auth.auth = false;
              auth.user = null;
            }}
          >
            Exit
          </Link>
        </div>
      </div>
      {children}
    </>
  );
});
