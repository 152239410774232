import React, { useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { axios } from "../axios";

import { auth } from "../store/auth";
import { InnerLayout } from "../layout/InnerLayout";

export const AuthPage = observer(() => {
  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
      if (localStorage.getItem("token") && !auth.auth) {
        try {
          const resp = await axios.get("/api/user/me");
          auth.auth = true;
          auth.user = resp.data;
        } catch (e) {
          navigate("/");
          return;
        }
      }

      if (!auth.auth) {
        navigate("/");
      }
    })();
  }, [auth.auth]);

  if (!auth.auth) return null;

  return (
    <InnerLayout>
      <Outlet />
    </InnerLayout>
  );
});
